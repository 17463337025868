<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Dashboard component
 */
import { GetDataMethods } from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "User Info",
      items: [
        {
          text: "Wesal",
        },
        {
          text: "Users",
          active: true,
        },
      ],
      i: 20,
      countPageLogs: 1,
      currentPageLogs: 1,
      countPage: 1,
      currentPage: 1,
      logsData: [],
      ordersData: [],
      paymentsData: [],
      userData: null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "Order ID" },
        { key: "count_units", sortable: false, label: "Count Units" },
        { key: "price", sortable: false, label: "Price" },
        { key: "phone", sortable: false, label: "Phone" },
        { key: "status", sortable: false, label: "Payment Status" },
        { key: "type_order", sortable: false, label: "Type Order" },
        { key: "service.name", sortable: false, label: "Service" },
      ],

      fieldsLogs: [
        { key: "id", sortable: true, label: "ID" },
        { key: "point", sortable: false, label: "Point" },
        { key: "point_now", sortable: false, label: "Point Now" },
        { key: "type", sortable: false, label: "Type" },
        { key: "order_id", sortable: false, label: "Order Id" },
        { key: "top_up_id", sortable: false, label: "Top Up Id" },
        { key: "user_id", sortable: false, label: "User Id" },
        { key: "created_at", sortable: false, label: "Created At" },
        { key: "updated_at", sortable: false, label: "Updated At" },
      ],


      fieldsPayments: [
        { key: "id", sortable: true, label: "ID" },
        { key: "point", sortable: false, label: "Point" },
        { key: "point_now", sortable: false, label: "Point Now" },
        { key: "type", sortable: false, label: "Type" },
        { key: "status", sortable: false, label: "Status" },
        { key: "top_up_id", sortable: false, label: "Top Up Id" },
        { key: "user_id", sortable: false, label: "User Id" },
        { key: "created_at", sortable: false, label: "Created At" },
        { key: "updated_at", sortable: false, label: "Updated At" },
      ],
    };
  },


  watch: {
    currentPage: function () {
      this.GetOrdersUser(this.currentPage);
    }
  },

  computed: {
    rowOrder() {
      return this.countPage
    }

  },

  props: ["id"],

  mounted() {
    this.GetUserData();
    this.GetOrdersUser();
    this.GetLogsData();
    this.GetPaymentsData();
  },

  methods: {
    ...GetDataMethods,

    GetLogsData() {
      this.getData("get_payments_user_by_id/" + this.id).then(
        res => {
          this.paymentsData = res.data.data.data

        }
      );
    },

    GetPaymentsData() {
      this.getData("get_withdrawals_user_by_id/" + this.id).then(
        res => {
          this.logsData = res.data.data.data

        }
      );
    },

    GetOrdersUser(page) {
      this.getData("get_order_user_by_userId/" + this.id + "?page=" + page).then((res) => {
        this.ordersData = res.data.data.orders
        this.countPage = res.data.data.countPage

      });
    },

    GetUserData() {
      this.getData("get_user_by_id/" + this.id).then((res) => {
        this.userData = res.data.data.user
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader class="ml-5" :title="title" :items="items" />
    <div class="row">
      <div class="card col-md-12">
        <div class="card-body row">
          <div class="col-md-4">
            <div class="block">
              <label class="display mr-3">User ID: </label><span class="mt-3 font-weight-bold">{{ userData.id }}</span>
            </div>
            <div class="block">
              <label class="display mr-3">User Name: </label><span class="mt-3 font-weight-bold">{{
                userData.username
              }}</span>
            </div>
            <div class="block">
              <label class="display mr-3">Phone: </label><span class="mt-3 font-weight-bold">{{ userData.phone }}</span>
            </div>
            <div class="block">
              <label class="display mr-3">Role: </label><span class="mt-3 font-weight-bold">{{ userData.role }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <!--Start Order Tab-->
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Orders</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6"></div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search"
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="ordersData" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn">
                    <template v-slot:cell(created_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>

                    <template v-slot:cell(status)="data">
                      <div class="badge-soft-success" v-if="data.value == 1" style="
                          border-radius: 15px;
                          width: 60px;
                          height: 22px;
                          text-align: center;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        ">
                        Success
                      </div>
                      <div class="badge-soft-danger" v-if="data.value == 0" style="
                          border-radius: 15px;
                          width: 40px;
                          height: 22px;
                          text-align: center;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        ">
                        Faill
                      </div>
                    </template>

                    <!-- <template v-slot:cell(action)>
                      <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template> -->
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rowOrder"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

              <!--Start Logs Tab-->
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Withdrawals Log</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search"
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="logsData" :fields="fieldsLogs" responsive="sm" :per-page="10"
                    :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template v-slot:cell(created_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>
                    <template v-slot:cell(updated_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>

                    <template v-slot:cell(type)="data">
                      <div class="badge-soft-success" v-if="data.value == 'in'" style="
                          border-radius: 15px;
                          width: 40px;
                          height: 22px;
                          text-align: center;
                          font-size: larger;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        ">
                        IN
                      </div>
                      <div class="badge-soft-danger" v-if="data.value == 'out'" style="
                          border-radius: 15px;
                          width: 40px;
                          height: 22px;
                          text-align: center;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        ">
                        OUT
                      </div>
                    </template>

                    <!-- <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);" 
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template> -->


                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <!-- <button  v-for="v in i" :key="v" @click="change_page(v)">{{v}}</button> -->
                        <b-pagination v-model="currentPage" :total-rows="countPage" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Payments Log</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search"
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="paymentsData" :fields="fieldsPayments" responsive="sm"
                    :per-page="10" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template v-slot:cell(created_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>
                    <template v-slot:cell(updated_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>

                    <template v-slot:cell(type)="data">
                      <div class="badge-soft-success" v-if="data.value == 'in'" style="
                          border-radius: 15px;
                          width: 40px;
                          height: 22px;
                          text-align: center;
                          font-size: larger;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        ">
                        IN
                      </div>
                      <div class="badge-soft-danger" v-if="data.value == 'out'" style="
                          border-radius: 15px;
                          width: 40px;
                          height: 22px;
                          text-align: center;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        ">
                        OUT
                      </div>
                    </template>

                    <!-- <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);" 
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template> -->


                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <!-- <button  v-for="v in i" :key="v" @click="change_page(v)">{{v}}</button> -->
                        <b-pagination v-model="currentPage" :total-rows="countPage" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
